import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Trainings from './Evaluations'
import EvaluationQuestions from './EvaluationQuestions'
import AppFormWizard from '../../../web-app-core/components/organisms/AppFormWizard'
import EvaluationResponse from './EvaluationResponse'
import EvaluationUsers from './EvaluationUsers'
import EvaluationRespondents from './EvaluationRespondents'
import RespondentUserEvaluationUsers from './RespondentUserEvaluationUsers'
import { useEffect, useState } from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]



const EvaluationRespondentPage = () => {
  const {id, evaluationUserId} = useParams()
  const BASE_URL = `${process.env.REACT_APP_API_URL}`

  // make API call to get evaluation details
  const [evaluation, setEvaluation] = useState<any>(null)
  useEffect(() => {
    const getEvaluation = async () => {
      const response = await axios.get(`${BASE_URL}/respondent-user/${id}/evaluation-user`)
      const data = response.data
      setEvaluation(data.data[0])
      console.log('getEvaluation data', data)
    }
    getEvaluation()
  }, [])



  const formObject = {
    endpoint: `${API_URL}/evaluation/${evaluation?.evaluationId}`,
    dataFields: [
      {
        name: 'dateCreated',
        label: 'Date Created',
        type: 'date',
      },
    ],
    tabs: null,
  }
  return (
    evaluation &&
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Questions</PageTitle>
              <RespondentUserEvaluationUsers />
            </>
          }
        />

        {/* <Route
          path='questions'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Questions</PageTitle>
              <EvaluationQuestions />
            </>
          }
        /> */}

        <Route
          path={`users`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
              <EvaluationUsers />
            </>
          }
        />

        <Route
          path={`respondents`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
              <EvaluationRespondents />
            </>
          }
        />

        <Route
          path={`evaluation-users`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
              <RespondentUserEvaluationUsers />
            </>
          }
        />

        {/* 
        <Route element={<Navigate to='/crafted/pages/profile/overview' />} /> */}
      </Route>
    </Routes>
  )
}

export default EvaluationRespondentPage
