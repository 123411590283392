import {ManageGroupItem, ManageItem} from '../../../web-app-core'
import {useParams} from 'react-router-dom'
import EvaluationResponse from './EvaluationResponse'
import AppFormWizard from '../../../web-app-core/components/organisms/AppFormWizard'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const EvaluationQuestions = () => {
  const {id} = useParams()

  const formObject = {
    listEndpoint: `${BASE_URL}/evaluation/${id}/question`,
    listTitle: 'Evaluation Questions',
    listFields: [
      {
        name: 'title',
        header: 'Question',
        // type: 'link',
        // path: '/polls',
      },
      {
        name: 'type',
        header: 'Type',
      },
      {
        name: 'optionA',
        header: 'Option A',
      },
      {
        name: 'optionB',
        header: 'Option B',
      },
      {
        name: 'optionC',
        header: 'Option C',
      },
      {
        name: 'optionD',
        header: 'Option D',
      },
      {
        name: 'optionE',
        header: 'Option E',
      },
    ],
    addEndpoint: `${BASE_URL}/evaluation/${id}/question`,
    addTitle: 'Add Question',
    addFields: [
      {
        name: 'title',
        label: 'Question',
        toolTip: 'Enter Title',
        placeholder: '',
        validation: {
          type: 'string',
          required: true,
        },
        type: 'text',
      },
      {
        name: 'type',
        label: 'Question Type',
        toolTip: 'Select the question type',
        placeholder: '',
        validation: {
          required: true,
        },
        type: 'dropdown',
        options: [
          {id: 'Assessment', title: 'Assessment'},
          {id: 'Effectiveness', title: 'Effectiveness'},
          {id: 'Multichoice', title: 'Multichoice'},
          {id: 'Free Text', title: 'Free Text'},
        ],
      },
      {
        name: 'optionA',
        label: 'Option A',
        toolTip: 'Enter Option A',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionB',
        label: 'Option B',
        toolTip: 'Enter Option B',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionC',
        label: 'Option C',
        toolTip: 'Enter Option C',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionD',
        label: 'Option D',
        toolTip: 'Enter Option D',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionE',
        label: 'Option E',
        toolTip: 'Enter Option E',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
      },
    ],
    updateEndpoint: `${BASE_URL}/evaluation-question`,
    updateTitle: 'Update Question',
    updateFields: [
      {
        name: 'title',
        label: 'Question',
        toolTip: 'Enter Title',
        placeholder: '',
        validation: {
          type: 'string',
          required: true,
        },
        type: 'text',
      },
      {
        name: 'type',
        label: 'Question Type',
        toolTip: 'Select the question type',
        placeholder: '',
        validation: {
          required: true,
        },
        type: 'dropdown',
        options: [
          {id: 'Assessment', title: 'Assessment'},
          {id: 'Effectiveness', title: 'Effectiveness'},
          {id: 'Free Text', title: 'Free Text'},
        ],
      },
      {
        name: 'optionA',
        label: 'Option A',
        toolTip: 'Enter Option A',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionB',
        label: 'Option B',
        toolTip: 'Enter Option B',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionC',
        label: 'Option C',
        toolTip: 'Enter Option C',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionD',
        label: 'Option D',
        toolTip: 'Enter Option D',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionE',
        label: 'Option EE',
        toolTip: 'Enter Option E',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
      },
      {
        name: 'orderIndex',
        label: 'Question Index',
        validation: {type: 'number'},
        type: 'number',

      },
    ],
  }

  return (
    <div className='card card-flush'>
      <ManageItem formObject={formObject} />
    </div>
  )
}

export default EvaluationQuestions
