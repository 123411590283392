import {ManageGroupItem, ManageItem} from '../../../web-app-core'
import {useParams} from 'react-router-dom'
import AppFormWizard from '../../../web-app-core/components/organisms/AppFormWizard'
import { useEffect, useState } from 'react'
import axios from 'axios'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const EvaluationResponse = () => {
  const {id, evaluationUserId} = useParams()
  const params = useParams()
  console.log('EvaluationResponse params', params)

  const BASE_URL = `${process.env.REACT_APP_API_URL}`

  // make API call to get evaluation details
  const [evaluation, setEvaluation] = useState<any>(null)
  const [evaluationUser, setEvaluationUser] = useState<any>(null)
  useEffect(() => {
    const getEvaluation = async () => {
      const response = await axios.get(`${BASE_URL}/respondent-user/${id}/evaluation-user`)
      const data = response.data
      console.log('getEvaluation data', data)
      console.log('getEvaluation evaluationUserId', evaluationUserId)
      setEvaluation(data.data[0])
      setEvaluationUser(data.data?.find((item: any) => item.id === evaluationUserId))
      console.log('getEvaluation data', data)
    }
    getEvaluation()
  }, [])

  return (
    evaluation && evaluationUser ?
    <div className='card card-flush my-3'>
      <AppFormWizard formObject={{redirectPath: `/evaluations/evaluation-users/${id}`, respondentUserId: evaluationUser?.respondentUserId, evaluationUserId: evaluationUser?.evaluationUserId, title: `${evaluationUser?.user?.firstName} ${evaluationUser?.user?.lastName}` ,description: 'Survey description here', id: evaluation?.evaluationId}}  />
    </div> : null
  )
}

export default EvaluationResponse
