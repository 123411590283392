import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Trainings from './Approvals'
import {ApprovalDetails} from './tabs/ApprovalDetails'
import ApprovalUsers from './tabs/ApprovalUsers'
import Responses from './tabs/Responses'
import {ApprovalUserDetails} from './tabs/ApprovalUserDetails'
import ApprovalDocuments from './tabs/ApprovalDocuments/ApprovalDocuments'
import ApprovalDocument from './tabs/ApprovalDocuments/ApprovalDocument'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ApprovalsPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/approval-request/${id}`,
    dataFields: [],
    tabs: [
      {
        title: 'Overview',
        path: `/approvals/${id}`,
      },
      {
        title: 'Members',
        path: `/approvals/${id}/members`,
      },
      {
        title: 'Responses',
        path: `/approvals/${id}/responses`,
      },
      {
        title: 'Documents',
        path: `/approvals/${id}/documents`,
      },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        {/* <Route element={<Navigate to=':id' />} /> */}
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <ApprovalDetails />
            </>
          }
        />
        <Route
          path={`members`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Members</PageTitle>
              <ApprovalUsers />
            </>
          }
        />
        <Route
          path={`responses`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Responses</PageTitle>
              <Responses />
            </>
          }
        />
        <Route
          path={`documents`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <ApprovalDocuments />
            </>
          }
        />
        <Route
          path={`documents/:id`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <ApprovalDocument />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ApprovalsPage
