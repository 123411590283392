import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function BoardMembers() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/user`,
    listTitle: 'Board Members',
    listFields: [
      {
        name: 'user.name',
        header: 'Name',
        type: 'link',
        path: '/board-members',
      },
      {
        name: 'boardRole',
        header: 'Board Role',
      },
      {
        name: 'userRole',
        header: 'User Role',
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'date',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
