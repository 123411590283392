import {ManageItem, useAuth} from '../../../web-app-core'
import {useParams} from 'react-router-dom'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const EvaluationResult = () => {
  const {currentUser} = useAuth()
  const companyId = currentUser?.companyId

  const {id, respondentUserEvaluationUserId} = useParams()

  const formObject = {
    listEndpoint: `${BASE_URL}/respondent-user-evaluation-user/${encodeURIComponent(respondentUserEvaluationUserId as string)}/response`,
    listTitle: 'Response',
    listFields: [
      {
        name: 'question.title',
        header: 'Question',
      },
      {
        name: 'answer',
        header: 'Answer',
      },
      {
        name: 'score',
        header: 'Score',
      },
    ],
  }

  return (
    <div className='card card-flush my-5'>
      <ManageItem formObject={formObject} />
    </div>
  )
}

export default EvaluationResult
