import {AgoraVideoPlayer, createScreenVideoTrack} from 'agora-rtc-react'
import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
  IAgoraRTCClient,
  ILocalTrack,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng'
import React, {useRef, useEffect, useState} from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../web-app-core'



export interface VideoPlayerProps {
  user: any | undefined
  style?: any | undefined
  screenShare?: Boolean
  client?: IAgoraRTCClient
  trackType: 'local' | 'remote'
  ws: WebSocket
  videoCallId: number
  localAudioTrack: IMicrophoneAudioTrack
  localVideoTrack: ICameraVideoTrack | undefined
}

const LocalScreenShareMediaPlayer = (props: VideoPlayerProps) => {
  const [audioOn, setAudioOn] = useState(true)
  const {currentUser, auth} = useAuth()
  const {id} = useParams()
  const{client, ws, videoCallId, trackType, localAudioTrack, localVideoTrack} = props

  const [searchParams, setSearchParams] = useSearchParams()
  const callVideoCallId = `${searchParams.get('videoCallId')}2` ?? videoCallId
  const callUserId = searchParams.get('userId') ?? auth?.user?.id
  const callChannelId = searchParams.get('channelId') ?? id

  const useScreenVideoTrack = createScreenVideoTrack({
    encoderConfig: '1080p_1',
    optimizationMode: 'detail',
  })
  const {ready: screenShareReady, tracks: screenShareTracks} = useScreenVideoTrack()

    // send a message to websocket server and wait for response containing an acknowlegement of the message action
    const sendWsMessage = (message: any) => {
      console.log('sendWsMessage message', message)
      if (ws == null) {
        return
      }
  
      ws.onmessage = (event) => {
        const messageObject = JSON.parse(event.data)
        if (messageObject.action === message.action) {
          console.log('sendWsMessage ws onmessage:' + messageObject.action, event)
          console.log('sendWsMessage clearInterval', messageObject)
          clearInterval(sendFunctionInterval)
        }
      }
      const sendFunctionInterval = setInterval(() => {
        console.log('sendWsMessage sendFunctionInterval', message)
        ws?.send(JSON.stringify(message))
      }, 1000)
    }

  useEffect(() => {
    if (screenShareReady) {
      client?.unpublish(localVideoTrack).then(() => {
        client?.publish([screenShareTracks as ILocalTrack]).then(() => {
          console.log('ws', ws)
          sendWsMessage({
            action: 'screen-share-started',
            data: {
              channelId: callChannelId,
              userId: callUserId,
              videoCallId: callVideoCallId,
            },
          })
      
        }).catch((err) => {
          console.log('publish error', err)
          Swal.fire({
            text: `Error publishing screen share`,
            icon: 'error',
            confirmButtonText: 'Okay',
          })
        })
      }).catch((err) => {
        console.log('unpublish error', err)
        Swal.fire({
          text: `Error publishing screen share 2`,
          icon: 'error',
          confirmButtonText: 'Okay',
        })
      })
    }
  }, [screenShareReady])



  return (
    <div className='join-video single-user col p-3 '>
      <div className='d-block h-500px'>
        {screenShareTracks &&
        <>
        <h3>Screenshare here</h3>
         <AgoraVideoPlayer
             id={`${props.user?.uid}-video-player`}
           className='vid rounded overflow-hidden bg-dark col'
           videoTrack={screenShareTracks as ILocalVideoTrack | IRemoteVideoTrack}
           style={{height: '95%', width: '95%'}}
         />
        </>
            
        }
     
      </div>
      <div className='part-name mt-n8 position-relative'>
      <h5 className='bg-active-dark text-white fs-8 rounded d-inline px-4'>Screenshare</h5>
      </div>
    </div>
  ) 
}

export default LocalScreenShareMediaPlayer
