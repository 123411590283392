import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Responses() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {currentUser} = useAuth()
  console.log('currentUser', currentUser?.companyId)
  const {id} = useParams()

  const companyId = currentUser?.companyId

  const formObject = {
    listEndpoint: `${API_URL}/approval-request/${id}/user-response`,
    listTitle: 'Responses',
    listFields: [
      {
        name: 'userName',
        header: 'Board Member',
        type: 'link',
        path: '/approvals/response',
      },
      {
        name: 'dateCreated',
        header: 'Date Responded',
        type: 'datetime',
      },
      // {
      //   name: 'reason',
      //   header: 'Reason',
      // },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: ``,
    addTitle: '',
    addFields: [],
  }

  return <ManageItem formObject={formObject} />
}
