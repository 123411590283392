import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Trainings from './Evaluations'
import EvaluationQuestions from './EvaluationQuestions'
import AppFormWizard from '../../../web-app-core/components/organisms/AppFormWizard'
import EvaluationResponse from './EvaluationResponse'
import EvaluationUsers from './EvaluationUsers'
import EvaluationRespondents from './EvaluationRespondents'
import EvaluationResult from './EvaluationResult'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EvaluationResultPage = () => {
  const {id, respondentUserEvaluationUserId} = useParams()
  const formObject = {
    endpoint: `${API_URL}/respondent-user-evaluation-user/${encodeURIComponent(respondentUserEvaluationUserId as string)}`,
    dataFields: [
      {
        name: 'firstName',
        label: 'First Name',
      },
      {
        name: 'lastName',
        label: 'Last Name',
      },
      {
        name: 'score',
        label: 'Score',
      },
      {
        name: 'dateUpdated',
        label: 'Date Submitted',
        type: 'date',
      },
    ],
    tabs: null,
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Evaluation Response</PageTitle>
              <EvaluationResult />
            </>
          }
        />


        {/* 
        <Route element={<Navigate to='/crafted/pages/profile/overview' />} /> */}
      </Route>
    </Routes>
  )
}

export default EvaluationResultPage
