// HTTP basic authentication example in node.js using the <Vg k="VSDK" /> Server RESTful API
import axios from "axios";
import * as https from "https";
import {Buffer} from 'buffer';
// Customer ID
const customerKey = "59b6a9f7782e45f5bc6f64b0c6e29c77";
// Customer secret
const customerSecret = "a1438d546cd74ef49b9987a926ca7532";
// Concatenate customer key and customer secret and use base64 to encode the concatenated string
const plainCredential = customerKey + ":" + customerSecret;
// Encode with base64
const encodedCredential = Buffer.from(plainCredential).toString("base64");
const authorizationField = "Basic " + encodedCredential;
const uid = '123456' + (Math.floor(Math.random() * 100) + 1).toString()
const cname = 'httpClient463224'

// const request = {
//   cname: cname,
//   uid: uid,
//   clientRequest: {
//     resourceExpiredHour: 24,
//     scene: 1,
//   },
// };

export const getToken2 = async (videoCallId: string) => {
  const axios = require("axios");
  axios.interceptors.request.use(
    (config: { headers: { [x: string]: string } }) => {
      config.headers["Authorization"] = authorizationField;
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  return await axios.post(
    "https://api.agora.io/v1/apps/441c950a328d4cae9c951d799541a9ab/cloud_recording/acquire",
    {
      cname: cname,
      uid: videoCallId,
      clientRequest: {
        resourceExpiredHour: 24,
        scene: 1,
      },
    }
  );
};

export const stopRecording = async (resourceId: string, sid: string, videoCallId: string) => {
  const requestBody = {
    cname: cname,
    uid: videoCallId,
    clientRequest: {},
  };

  const axios = require("axios");
  axios.interceptors.request.use(
    (config: { headers: { [x: string]: string } }) => {
      config.headers["Authorization"] = authorizationField;
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  return await axios.post(
    `https://api.agora.io/v1/apps/441c950a328d4cae9c951d799541a9ab/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/web/stop`,
    requestBody
  );
};

export const startRecording = async (
  resourceId: string,
  meetingId: string,
  tenantId: string,
  videoCallId: string
) => {
   const baseUrl = `${window.location.protocol}//${window.location.host}`
  // const baseUrl = `https://devapp.dcsleconnect.com.ng`
  // const baseUrl2 = `https://dev.ds7i3hc443doh.amplifyapp.com`
  // console.log('startRecording baseUrl', baseUrl)
  const requestBody = {
    cname: cname,
    uid: videoCallId,
    clientRequest: {
      extensionServiceConfig: {
        errorHandlePolicy: 'error_abort',
        extensionServices: [
          {
            serviceName: 'web_recorder_service',
            errorHandlePolicy: 'error_abort',
            serviceParam: {
              // url: `https://meet.dcsleconnect.com.ng?channel=${meetingId}&tenantId=${tenantId}&recordingUid=${uid}`,
              url: `${baseUrl}/meet?channelId=${meetingId}&userId=${videoCallId}&videoCallId=${videoCallId}&resourceId=${resourceId}`,
              audioProfile: 0,
              videoWidth: 1280,
              videoHeight: 720,
              maxRecordingHour: 1,
            },
          },
        ],
      },
      recordingFileConfig: {
        avFileType: ['hls', 'mp4'],
      },
      storageConfig: {
        vendor: 1,
        region: 5,
        bucket: "dcsl-econnect-recordings",
        accessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretKey: process.env.REACT_APP_AWS_SECRET_KEY,
        fileNamePrefix: ["meetings", "recordings"],
      },
    },
  }

  const axios = require("axios");
  axios.interceptors.request.use(
    (config: { headers: { [x: string]: string } }) => {
      config.headers["Authorization"] = authorizationField;
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  return await axios.post(
    `https://api.agora.io/v1/apps/441c950a328d4cae9c951d799541a9ab/cloud_recording/resourceid/${resourceId}/mode/web/start`,
    requestBody
  );
};
