import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Meetings() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id;

 
  useEffect(() => {
    console.log('currentTenant', currentTenant)
    if(!auth?.currentTenant?.id) {
      console.log('no current tenant')
      navigate('/companies')
    }
  }, [auth])

  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/meeting`,
    listTitle: 'Meetings',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/meetings',
      },
      {
        name: 'startTime',
        header: 'Start',
        type: 'datetime',
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'date',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/company/${currentTenant}/meeting`,
    addTitle: 'Add Meeting',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
			{
				name: 'startTime',
				label: 'Start',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
			{
				name: 'endTime',
				label: 'End',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
    ],
    updateEndpoint: `${API_URL}/meeting`,
    updateTitle: 'Edit Meeting',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
			{
				name: 'startTime',
				label: 'Start',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
			{
				name: 'endTime',
				label: 'End',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
    ],
  }

  return <ManageItem formObject={formObject} />
}
