import {AgoraVideoPlayer} from 'agora-rtc-react'
import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
  IAgoraRTCClient,
  ILocalTrack,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
} from 'agora-rtc-sdk-ng'
import React, {useRef, useEffect, useState} from 'react'

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | ICameraVideoTrack | undefined
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined
  user: any | undefined
  style?: any | undefined
  screenShare?: Boolean
  client?: IAgoraRTCClient
  agoraRemoteUser?: IAgoraRTCRemoteUser
  trackType: 'local' | 'remote'
  userCount: number
  sendWsMessage: Function
  userTrackState: 'active' | 'passive' | undefined
  audioOn?: boolean
  videoOn?: boolean
  callUser?: any
  authUser?: any
}

const MediaPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null)
  const [videoOn, setVideoOn] = useState(
    props.trackType === 'remote' ? props.agoraRemoteUser?.hasVideo : true
  )
  const [userVolume, setUserVolume] = useState(0)
  const [audioOn, setAudioOn] = useState(props.agoraRemoteUser?.hasAudio)
  const [removeUserClicked, setRemoveUserClicked] = useState(false)
  const [callUser, setCallUser] = useState(props.callUser)
  const [remoteUser, setRemoteUser] = useState(props.agoraRemoteUser)
  const [screenShare, setScreenShare] = useState(false)
  const [localAudioTrack, setLocalAudioTrack] = useState<IRemoteAudioTrack | undefined>(
    props.audioTrack as IRemoteAudioTrack
  )
  const [videoSize, setVideoSize] = useState({
    height: '43vh',
  })
  const [cols, setCols] = useState(3)
  const {client, userCount, sendWsMessage, user} = props

  useEffect(() => {
    setVideoOn(props.audioOn)
    setAudioOn(props.videoOn)
    setRemoteUser(props.agoraRemoteUser)
    setCallUser(props.callUser)
  }, [props.agoraRemoteUser, props.screenShare, props.audioOn, props.videoOn, JSON.stringify(props.callUser)])

  useEffect(() => {
    setCallUser(props.callUser)
    console.log('dc callUser', props.callUser)
  }, [props.callUser, JSON.stringify(props.callUser)])

  useEffect(() => {
    //client?.removeAllListeners()
    // client?.on('user-info-updated', (uid: number, msg: string) => {
    //   if (uid !== Number(props.user?.videoCallId)) {
    //     return
    //   }

    //   console.log('dc user-info-updated', uid, msg)
    //   switch (msg) {
    //     case 'mute-audio':
    //       setAudioOn(false)
    //       break
    //     case 'mute-video':
    //       setVideoOn(false)
    //       break
    //     case 'unmute-audio':
    //       props.client?.enableAudioVolumeIndicator()
    //       setAudioOn(true)
    //       break
    //     case 'unmute-video':
    //       setVideoOn(true)
    //       break
    //   }
    // })
    // client?.on('user-published', async (user, mediaType) => {
    //   console.log('other subscribe success', user.uid, props.user?.videoCallId, mediaType)
    //   console.log('up level', user.audioTrack, props.agoraRemoteUser?.audioTrack)

    //   if (user.uid !== Number(props.user?.videoCallId)) {
    //     return
    //   }
    //   setLocalAudioTrack(user.audioTrack)
    //   await client
    //     .subscribe(user, mediaType)
    //     .then(() => {
    //       console.log('other subscribe success', user.uid, mediaType)
    //     })
    //     .catch((err) => {
    //       console.log('subscribe error', err)
    //     })
    //   console.log('new subscribe success', user.uid, props.user?.videoCallId, mediaType)

    //   console.log('new subscribe success 22', user.uid, props.user?.videoCallId, mediaType)
    //   //props.videoTrack?.stop()
    //   if (mediaType === 'audio') {
    //     setAudioOn(true)
    //   }
    //   if (mediaType === 'video') {
    //     console.log('new subscribe success 171', user.uid, props.user?.videoCallId, mediaType)
    //     if (user.uid === props.user?.videoCallId) {
    //     setRemoteUser(user)
          
    //     }
    //     // props.client?.publish(props.videoTrack as ILocalTrack)
    //     // props.videoTrack?.play(`${props.user?.uid}-video-player`)
    //     setVideoOn(false)
    //     setTimeout(() => {
    //       setVideoOn(true)
    //     }, 1000)
    //   }
    // })
    // client?.on('user-joined', async (user) => {
    //   console.log('user join success', user)
     
    // })

    // client?.on('user-unpublished', (user, mediaType) => {
    //   console.log('unpublished', user, mediaType)
    //   console.log('unpublished props', user.uid, props.agoraRemoteUser?.uid, mediaType)
    //   if (user.uid !== props.agoraRemoteUser?.uid) {
    //     return
    //   }

    //   if (mediaType === 'audio') {
    //     setAudioOn(false)
    //   }
    //   if (mediaType === 'video') {
    //     // client.unpublish(props.videoTrack as ILocalTrack)
    //     //props.videoTrack?.stop()
    //     console.log('unpublished props hiding video', user.uid, props.agoraRemoteUser?.uid, mediaType)

    //     setVideoOn(false)
    //   }
    // })

    return () => {
      //client?.removeAllListeners()
    }
  }, [])

  // get audio volume every 1 second and update state
  let interval: NodeJS.Timeout 
  useEffect(() => {
    props.client?.enableAudioVolumeIndicator()
    clearInterval(interval)
    interval = setInterval(() => {
      props.agoraRemoteUser?.audioTrack?.getVolumeLevel()
      // console.log('props.agoraRemoteUser?.audioTrack?.getVolumeLevel()', props.agoraRemoteUser?.audioTrack?.getVolumeLevel())
      // console.log('props?.audioTrack?.getVolumeLevel()', props.audioTrack?.getVolumeLevel())
      setUserVolume(props.agoraRemoteUser?.audioTrack?.getVolumeLevel() ?? 0)
      if (props.agoraRemoteUser?.audioTrack) {
        // setUserSpeaking(props.agoraRemoteUser?.audioTrack?.getVolumeLevel() > 0.6)
      }
    }, 1000)
    
    return () => clearInterval(interval)
  }, [props.agoraRemoteUser?.uid, props.audioOn])

  //const videoSize = { width: "160px", height: "120px"}
  useEffect(() => {
    // if (Number(props.screenshareSocketUser?.videoCallId) === Number(props.agoraRemoteUser?.uid)) {
    //   setVideoSize({height: '180px'})
    //   setCols(12)
    // } else
    switch (props.userCount + 1) {
      case 1:
        setVideoSize({height: '30vh'})
        setCols(3)
        break
      case 2:
      case 4:
      case 3:
      case 5:
      case 6:
        setVideoSize({height: '30vh'})
        setCols(3)
        break

      default:
        setVideoSize({height: '30vh'})
        setCols(3)
        break
    }
  }, [props.userCount, screenShare])

  return (
    // <div ref={container}  className="video-player" style={{ width: "320px", height: "240px"}}></div>

   ( true
  //  !props?.agoraRemoteUser || 
  //  !props?.agoraRemoteUser?.uid?.toString().includes('123456')
    ) ? (
      <div
        className={`join-video single-user col${props.screenShare ? '' : '-' + cols} p-3 `}
        style={props.style ? props.style : videoSize}
      >
        {/* <h1>a-{props.authUser?.userRole}</h1> */}
        {/* <h1>{props.userTrackState}</h1>
        <h1>CA:{callUser?.audioOn?.toString()},CV:{callUser?.videoOn?.toString()}</h1> */}
        {/* <h1>{user?.id}{videoOn?.toString()}{props?.agoraRemoteUser?.hasVideo?.toString() }-{(!!remoteUser?.videoTrack)?.toString()}</h1> */}
        {/* <h1>{client?.remoteUsers.length}</h1>
  <h2>A-{props.audioTrack?.getVolumeLevel()?.toString()}| B-{localAudioTrack?.getVolumeLevel()?.toString()}| C-{userVolume}</h2>
  <h2>Volume: ={userVolume}= {props.agoraRemoteUser?.audioTrack?.getVolumeLevel().toString()} - {props.audioTrack?.getVolumeLevel().toString()}</h2> */}
        <div className='d-block h-100'>
          {/* <><h2>v:{videoOn?.toString()}, a:{audioOn?.toString()}</h2></> */}
          {callUser?.videoOn && ((props?.agoraRemoteUser?.hasVideo && remoteUser?.videoTrack) || props.trackType !== 'remote')? (
            <AgoraVideoPlayer
              id={`${props.user?.uid}-video-player`}
              className={`vid rounded overflow-hidden bg-dark col ${
                userVolume && userVolume > 0.6 ? 'border border-4 border-light border-primary' : ''
              }`}
              videoTrack={(props?.agoraRemoteUser?.videoTrack ?? remoteUser?.videoTrack ?? props.videoTrack) as ILocalVideoTrack | IRemoteVideoTrack} 
              style={{height: '95%', width: '95%'}}
            />
          ) : (
            <div
              className={`vid rounded overflow-hidden bg-dark col d-flex flex-center ${
                userVolume && userVolume > 0.6 ? 'border border-4 border-light border-primary' : ''
              }`}
              style={{height: '95%', width: '95%'}}
            >
              <i className='fa fa-video-slash fs-5hx me-1 d-flex '></i>
            </div>
          )}
          <div className='part-name mt-n8 ms-3 position-relative'>
            {props.screenShare && !props.user ? (
              <h4>Screenshare</h4>
            ) : (
              <>
                <h5 className='bg-dark text-white fs-8 rounded d-inline px-2'>
                  {props.user?.name ? `${props.user?.name}` : 'Guest User'}

                  <button
                    disabled={!props.audioOn && props.authUser?.userRole !== 'Admin'}
                    onClick={() => {
                      sendWsMessage({
                        action: 'mute-audio',
                        data: {
                          userId: props.user.userId,
                        },
                      }) // Send a message to the server to remove the user from the call
                      // alert(`User ${props.agoraRemoteUser?.uid} has muted their audio`)
                      setAudioOn(false)
                    }}
                    className='bg-transparent border-0 px-1'
                  >
                    {props.trackType === 'remote' && (
                      <i
                        className={` fa text-${!props.audioOn ? 'danger' : 'white'} fa-microphone${
                          props.audioOn ? '' : '-slash'
                        } p-1 rounded-3`}
                      />
                    )}
                  </button>
                  {props.trackType === 'remote' && props.authUser?.userRole === 'Admin' && (
                    <button
                      disabled={removeUserClicked}
                      onClick={() => {
                        sendWsMessage({
                          action: 'remove-user',
                          data: {
                            userId: props.user.userId,
                          },
                        }) // Send a message to the server to remove the user from the call
                        // alert(`User ${props.agoraRemoteUser?.uid} has muted their audio`)
                        setRemoveUserClicked(true)
                      }}
                      className='btn py-0 pe-1 btn-xs bg-white border-0 px-1 mx-2 mb-1 '
                    >
                      <i
                        className={` fa text-black fa-times ${
                          props.audioOn ? '' : '-slash'
                        } p-1 rounded-3`}
                      />
                    </button>
                  )}
                </h5>
              </>
            )}
          </div>
        </div>
      </div>
    ) : null
  )
}

export default MediaPlayer
