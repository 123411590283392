import {AgoraVideoPlayer, createScreenVideoTrack} from 'agora-rtc-react'
import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
  IAgoraRTCClient,
  ILocalTrack,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
} from 'agora-rtc-sdk-ng'
import React, {useRef, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useAuth } from '../../../../web-app-core'

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | ICameraVideoTrack | undefined
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined
  user: any | undefined
  style?: any | undefined
  screenShare?: Boolean
  client?: IAgoraRTCClient
  agoraRemoteUser?: IAgoraRTCRemoteUser
  trackType: 'local' | 'remote'
  ws: WebSocket
  videoCallId: number
}

const RemoteScreenShareMediaPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null)
  const [videoOn, setVideoOn] = useState(true)
  const [audioOn, setAudioOn] = useState(true)
  const [remoteUser, setRemoteUser] = useState(props.agoraRemoteUser)
  const {currentUser} = useAuth()

  const [screenShare, setScreenShare] = useState(false)
  const [videoSize, setVideoSize] = useState({
    width: '320px',
    height: '240px',
  })
  const {id} = useParams()
  const{client, ws, videoCallId, videoTrack} = props


  client?.on("user-published", async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    console.log("new subscribe success", user.uid, props.agoraRemoteUser?.uid, mediaType);

    if (user.uid !== props.agoraRemoteUser?.uid) {
        return
    } 
   
    if (mediaType === "audio") {
        setAudioOn(true);
    }
    if (mediaType === "video") {
        // props.client?.publish(props.videoTrack as ILocalTrack)
        // props.videoTrack?.play(`${props.user?.uid}-video-player`)
        // setVideoOn(true);
    }
  });

  client?.on("user-unpublished", (user, mediaType) => {
    console.log("unpublished", user, mediaType);
    if (user.uid !== props.agoraRemoteUser?.uid) {
        return
    }

    if (mediaType === "audio") {
        setAudioOn(false);
    }
    if (mediaType === "video") {
        // client.unpublish(props.videoTrack as ILocalTrack)
        // props.videoTrack?.stop()
        // setVideoOn(false);
    }
  });



  return (
    // <div ref={container}  className="video-player" style={{ width: "320px", height: "240px"}}></div>
    <div className='join-video single-user col p-3 '>
      <div className='d-block h-500px'>
        {videoTrack &&
        <>
         <AgoraVideoPlayer
             id={`${props.user?.uid}-video-player`}
           className='vid rounded overflow-hidden bg-dark col'
           videoTrack={videoTrack}
           style={{height: '95%', width: '95%'}}
         />
        </>
            
        }
     
      </div>
      <div className='part-name mt-n8 position-relative mx-3'>
        {props.screenShare && !props.user ? (
           <h5 className='bg-active-dark text-white fs-8 rounded d-inline px-2'>Remote Screenshare</h5>
        ) : (
          <h5 className='bg-dark text-white fs-8 rounded d-inline px-2'>
            {props.user ? `${props.user?.name}` : 'Guest User'}  
            {(remoteUser) && (
              <button
                onClick={() => {
                  // alert(`User ${props.agoraRemoteUser?.uid} has muted their audio`)
                }}
                className='bg-transparent border-0 px-1'
              >
                <i className={` fa text-white fa-microphone${audioOn ? '': '-slash'} p-1 rounded-3`} />
              </button>
            )}
          </h5>
        )}
      </div>
    </div>
  ) 
}

export default RemoteScreenShareMediaPlayer
