import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Documents() {

  const {auth} = useAuth()

  const formObject = {
    listEndpoint: `${API_URL}/document/parent/${auth?.user?.id}`,
    listTitle: 'Document Folders',
    listFields: [
      {
        name: 'fileName',
        header: 'Title',
        type: 'link',
        path: '/document-folders',
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'datetime',
      },
    ],
    addEndpoint: `${API_URL}/document/parent/${auth?.user?.id}`,
    addTitle: 'Create Document Folder',
    addFields: [
      {
        name: 'fileName',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
    updateEndpoint: `${API_URL}/document`,
    updateTitle: 'Update Document Folder',
    updateFields: [
      {
        name: 'fileName',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
