import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function EvaluationRespondents() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const formObject = {
    listEndpoint: `${API_URL}/user/${auth?.user?.id}/respondent-user`,
    listTitle: 'Evaluation',
    listFields: [
      {
        name: 'evaluation.title',
        header: 'Title',
        type: 'link',
        path: 'evaluation-users',
      },
      {
        name: 'evaluation.type',
        header: 'Type',
      },
      // {
      //   name: 'description',
      //   header: 'Description',
      // },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'date',
      },
      // {
      //   name: 'status',
      //   header: 'Status',
      //   type: 'status',
      // },
    ],

  }

  return <ManageItem formObject={formObject} />
}
