import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, useAuth} from '../../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function DocumentList() {
  const {auth} = useAuth()
  const {id} = useParams()

  const formObject = {
    listEndpoint: `${API_URL}/document/parent/${id}`,
    listTitle: 'Uploaded Documents',
    listFields: [
      {
        name: 'fileName',
        header: 'Title',
        type: 'link',
        path: `/document-folders/${id}/documents`,
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'datetime',
      },
    ],
    addEndpoint: `${API_URL}/document/parent/${id}`,
    addTitle: 'Upload Document',
    addFields: [
      {
        name: 'fileName',
        type: 'na',
        validation: {
          type: 'string',
        },
      },
      {
        name: 'fileType',
        type: 'na',
        validation: {
          type: 'string',
        },
      },
      {
        name: 'fileObject',
        type: 'na',
        validation: {
          type: 'object',
        },
      },
      {
        name: 'fileUrl',
        label: 'Select file',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'file-upload',
        region: 'eu-west-2',
        bucketName: 'dcsl-econnect-recordings',
      },
    ],
    updateEndpoint: `${API_URL}/document`,
    updateTitle: 'Update Document',
    updateFields: [
      {
        name: 'fileName',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
