import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import TrainingCall from './Tabs/TrainingCall'
import TrainingDocuments from './Tabs/TrainingDocuments/TrainingDocuments'
import TrainingDocument from './Tabs/TrainingDocuments/TrainingDocument'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TrainingsPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/training/${id}`,
    dataFields: [
      {
        name: 'startTime',
        label: 'Start',
        type: 'datetime',
      },
      {
        name: 'endTime',
        label: 'End',
        type: 'datetime',
      },
      {
        name: 'dateCreated',
        label: 'Date Created',
        type: 'date',
      },
    ],
    tabs: [
      {
        title: 'Call',
        path: `/trainings/${id}`,
      },
      {
        title: 'Documents',
        path: `/trainings/${id}/documents`,
      },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Training Call</PageTitle>
              <TrainingCall />
            </>
          }
        />

        <Route
          path={`documents`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <TrainingDocuments />
            </>
          }
        />

        <Route
          path={`documents/:id`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <TrainingDocument />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default TrainingsPage
