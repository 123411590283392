import {ManageItem, useAuth} from '../../../web-app-core'
import {useParams} from 'react-router-dom'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const EvaluationUsers = () => {
  const {currentUser} = useAuth()
  const companyId = currentUser?.companyId

  const {id} = useParams()

  const formObject = {
    listEndpoint: `${BASE_URL}/evaluation/${id}/user`,
    listTitle: 'Evaluation User',
    
    listFields: [
      {
        name: 'user.firstName',
        header: 'First Name',
        type: 'link',
        path: `/admin/evaluations/${id}/respondent-users`,
      },
      {
        name: 'user.lastName',
        header: 'Last Name',
      },
    ],
    addEndpoint: `${BASE_URL}/evaluation/${id}/user`,
    addTitle: 'Add User',
    addFields: [
      {
        name: 'evaluationUserId',
        label: 'Board Member',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        optionsEndpoint: `${BASE_URL}/company/${companyId}/user?limit=1000`,
      },
    ],
  }

  return (
    <div className='card card-flush my-5'>
      <ManageItem formObject={formObject} />
    </div>
  )
}

export default EvaluationUsers
