/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import ReactTooltip from 'react-tooltip'
import ReactS3Client from 'react-aws-s3-typescript'
import Dropzone from 'react-dropzone'
import {v4 as uuidv4} from 'uuid'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
  setError: Function
}

const FileUploadInputWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
  setError,
}: InputComponentProps) => {
  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const [loading, setLoading] = useState(false)
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }

  const onDrop = useCallback((acceptedFiles: any, form: any) => {
    // setError("");
    acceptedFiles.forEach((file: File) => {
      const fileSize = file.size / 1024 / 1024
      if (fileSize > field.limit ?? 5) {
        return setError('Image Size Exceeds 5MB')
      }

      uploadFileToS3Bucket(file, uuidv4(), form)

      //   setYourImage(file);
      //   setImage(file);
      // const reader = new FileReader();
      // reader.addEventListener("load", () =>
      //   setImgSrc(reader.result?.toString() || "")
      // );
      // reader.readAsDataURL(file);
      // if (field.type === "Image") {
      //   setShowImageCropperModal(true);
      // } else {
      //   setLoading(true);

      //   uploadFileToS3Bucket(file, uuidv4());

      // }
    })
  }, [])

  const uploadFileToS3Bucket = (file: any, fileName: string, form: any) => {
    setLoading(true)
    console.log('upload file', file)
    //s3 bucket config
    const s3Config = {
      bucketName: field.bucketName, // "dcsl-econnect-recordings",
      // dirName: 'photos' /* optional */,
      region: field.region, // "eu-west-2",
      accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_KEY}`,
    }
    const reactS3Client = new ReactS3Client(s3Config)
    reactS3Client
      .uploadFile(file, fileName)
      .then(async (data: any) => {
        console.log(data)
        setInputValue(data.location)

        let objectValue = {} as any
        objectValue[field.name as string] = data.location
        if (field.type === 'file-upload') {
          objectValue['fileName'] = file.name
          objectValue['fileUrl'] = data.location
          objectValue['fileType'] = file.type
          objectValue['fileObject'] = {
            name: file.name,
            url: data.location,
            type: file.type,
          }

          form.setFieldValue(field.name, data.location)
          form.setFieldValue('fileName', file.name)
          form.setFieldValue('fileUrl', data.location)
          form.setFieldValue('fileType', file.type)
          form.setFieldValue('fileObject' as string, {
            name: file.name,
            url: data.location,
            type: file.type,
          })
        } else {
          form.setFieldValue(field.name, data.location)
        }
        form.setFieldValue(field.name as string, data.location)
        updateData(objectValue)
        // 1 second delay to show the loading
        await new Promise((r) => setTimeout(r, 1000))
        form.handleSubmit(form.values)
      })
      .catch((err: any) => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  console.log('touched field name', touched[field.name])
  // console.log('errors', errors)
  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-7 fw-bold mb-0 ms-1 ${
          field.validation.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top' effect='solid'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>

      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div>
            <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, form)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  {inputValue ? (
                    <>
                      <button className='btn btn-outline btn-outline-primary'>
                        <i className='fa fa-file'></i> {form.values['fileName']}
                      </button>
                    </>
                  ) : (
                    <div
                      className='notice d-flex bg-light-primary rounded border-primary border border-dashed cursor-pointer py-10 d-flex align-content-center justify-content-center'
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <p>
                          Drag and drop or <span className='text-info'>Browse</span>
                        </p>
                        <p>
                          Max image size is <span className='text-info'>5MB</span>
                        </p>
                      </div>
                    </div>
                  )}
                </section>
              )}
            </Dropzone>

            {/* 

            <input
              id={`input-${field.name}-id`}
              type='text'
              className='form-control form-control-lg fw-semibold'
              {...field}
              placeholder={field.placeholder}
            /> */}
            {/* <ErrorMessage name={field.name} /> */}

            {touched[field.name] && errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {/* <input
        type="text"
        className="form-control form-control-lg fw-semibold"
        name="appname"
        placeholder=""
        value={data && data[field.name]}
        onChange={(e) =>
          onChange(e)
        }
      /> */}
      {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  )
}

export {FileUploadInputWithLabel}
