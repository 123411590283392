/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../../web-app-core'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {ThemeModeType, useThemeMode} from '../../theme-mode/ThemeModeProvider'

const HeaderUserMenu: FC = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-testid='header-user-menu'
    >
      <div className='menu-item px-5 fs-5'>
        <div className='fw-bolder fs-5'>
          {currentUser?.firstName} {currentUser?.lastName}
        </div>

        <div className='fw-bold text-muted text-hover-primary fs-7'>
          {currentUser?.emailAddress}
        </div>
      </div>

      <div className='separator my-2'></div>

       <div className='menu-item px-5 fs-5'>
        <Link to='/profile' className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5 fs-5'>
        <a data-testid='sign-out' onClick={() => navigate('/logout')} className='menu-link'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
