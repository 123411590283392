import {useEffect, useState} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'
import {useParams} from 'react-router-dom'
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
  createScreenVideoTrack,
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  ConnectionState,
} from 'agora-rtc-react'
import MediaPlayer from '../../meetings/components/MediaPlayer'
import {CreateAppModal} from '../../../../_metronic/partials'
import clsx from 'clsx'
import {useLayout} from '../../../../_metronic/layout/user/core'
import RemoteScreenShareMediaPlayer from '../../meetings/components/RemoteScreenShareMediaPlayer'
import LocalScreenShareMediaPlayer from '../../meetings/components/LocalScreenShareMediaPlayer'
import axios from 'axios'
import {getToken2, startRecording, stopRecording} from '../../../../get-token'
import Swal from 'sweetalert2'
import NewWindow from 'react-new-window'
import CopyToClipboard from 'react-copy-to-clipboard'

// define config for rtc engine
const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}

const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()
const useScreenVideoTrack = createScreenVideoTrack({
  encoderConfig: '1080p_1',
  optimizationMode: 'detail',
})

const appId: string = '441c950a328d4cae9c951d799541a9ab' //ENTER APP ID HERE
const token: string | null = null

const API_URL = process.env.REACT_APP_API_URL

export default function TrainingCall() {
  const navigate = useNavigate()
  const {id} = useParams()
  const {currentUser, auth} = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()

  const callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  const callUserId = searchParams.get('userId') ?? auth?.user?.id
  const callChannelId = searchParams.get('channelId') ?? id

  const [inCall, setInCall] = useState(false)
  const [inlineCall, setInlineCall] = useState(false)
  const [channelId, setChannelId] = useState(id as string)

  useEffect(() => {
    if (!channelId) {
      setInlineCall(true)
    }
  }, [])

  return (
    <>
      {!inCall && (callUserId === '123456' || inlineCall) ? (
        <>
          <VideoCall
            setInCall={setInCall}
            setInlineCall={setInlineCall}
            channelId={callChannelId as string}
          />
        </>
      ) : (
        <>
          <div className='card py-5'>
            <div className='card-body py-15 py-lg-20'>
              <h1 className='fw-bolder text-gray-900 mb-5'>Join training call</h1>

              <div className='mb-11'>
                <button
                  className='btn btn-primary'
                  onClick={(e) => {
                    // setInCall(!inCall)
                    setInlineCall(!inlineCall)
                  }}
                >
                  {`${inCall ? 'Leave' : 'Join'}`} Call
                </button>
                {inCall && (
                  <NewWindow>
                    <VideoCall
                      setInCall={setInCall}
                      setInlineCall={setInlineCall}
                      channelId={channelId}
                    />
                  </NewWindow>
                )}
              </div>

              <div className='card card-bordered'>
                <div className='card-body'>
                  <div className='mb-11'>
                    <h5>
                      Meeting Link:{' '}
                      {`${window.location.protocol}//${window.location.host}/meet?channelId=${callChannelId}&userId=11&videoCallId=11&invite=1`}
                    </h5>
                    <CopyToClipboard
                     onCopy={() => {Swal.fire('Link Copied!', '', 'success')}}
                      text={`${window.location.protocol}//${window.location.host}/meet?channelId=${callChannelId}&userId=11&videoCallId=11&invite=1`}
                    >
                      <button className='btn btn-light-dark btn-sm'>Copy Link</button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

              <div className='mb-0'>
                <img
                  src='/metronic8/demo1/assets/media/auth/please-verify-your-email.png'
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                ></img>
                <img
                  src='/metronic8/demo1/assets/media/auth/please-verify-your-email-dark.png'
                  className='mw-100 mh-300px theme-dark-show'
                  alt=''
                ></img>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div className='h-125px'>
        <button
          className='btn btn-primary'
          onClick={(e) => {
            setInCall(!inCall)
          }}
        >
          {`${inCall ? 'Leave' : 'Join'}`} Call
        </button>
        {inCall && (
          <NewWindow>
            <VideoCall setInCall={setInCall} channelId={channelId} />
          </NewWindow>
        )}
      </div> */}
    </>
  )
}

const VideoCall = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
  setInlineCall: React.Dispatch<React.SetStateAction<boolean>>
  channelId: string
}) => {
  const {setInCall, channelId, setInlineCall} = props
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])
  const [start, setStart] = useState<boolean>(false)
  const [videoCallId, setVideoCallId] = useState<number>()
  const [remoteScreenShareUser, setRemoteScreenShareUser] = useState<IAgoraRTCRemoteUser>()
  const client = useClient()
  const {ready, tracks} = useMicrophoneAndCameraTracks()
  // const {ready: screenShareReady, tracks: screenShareTracks} = useScreenVideoTrack()
  const {currentUser, auth} = useAuth()
  const {id} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const {classes} = useLayout()
  const [startScreenShare, setStartScreenShare] = useState<boolean>(false)
  const [alreadyScreenSharing, setAlreadyScreenSharing] = useState<boolean>(false)
  const [trackState, setTrackState] = useState({video: true, audio: true})
  const [ws, setWs] = useState<WebSocket | undefined>()
  const [screenShareVideoCallId, setScreenShareVideoCallId] = useState<string | undefined>()
  const [socketConnections, setSocketConnections] = useState<Array<any>>()

  const [resourceId, setResourceId] = useState('')
  const [sid, setSid] = useState('')
  const [stopRecordingLoading, setStopRecordingLoading] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [recordingVideoCallId, setRecordingVideoCallId] = useState('123')

  console.log('auth?.user', auth?.user)
  const callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  const callUserId = searchParams.get('userId') ?? auth?.user?.id
  const callChannelId = searchParams.get('channelId') ?? channelId
  const callName = searchParams.get('name') ?? auth?.user?.name
  const callTenantId = searchParams.get('tenantId') ?? auth?.user?.name

  console.log('callChannelId cid', callChannelId)

  // const GET_CONNECTIONS_URL = `${API_URL}/channel/${id}/socket-connections`
  const GET_CONNECTIONS_URL = `${API_URL}/channel/${callChannelId}/socket-connection`

  const recordingUid = searchParams.get('recordingUid')

  client.on('connection-state-change', (connection: ConnectionState) => {
    console.log('state connection', connection)
    if (recordingUid && connection == 'CONNECTED') {
      console.log('state connection 001', connection)

      client.unpublish()
    }
  })

  const startMeetingRecording = async () => {
    const uid = '123456' + (Math.floor(Math.random() * 100) + 1).toString()
    setRecordingVideoCallId(uid)
    const testResp = await getToken2(uid)
    // console.log("resourceid", testResp.data.resourceId);
    setResourceId(testResp.data.resourceId)

    const secondResp = await startRecording(
      testResp.data.resourceId,
      callChannelId as string,
      callTenantId as string,
      uid as string
    )

    setSid(secondResp.data.sid)
  }

  const saveMeetingRecordingFileUrl = async (fileUrl: string) => {
    return await axios.post(
      `https://dcsl-emeeting-platform.azurewebsites.net/api/app/meeting/${callChannelId}/meeting-recording-file-url`,
      {
        title: 'Recording',
        videoUrl: fileUrl,
        tenantId: callTenantId,
      }
    )
  }

  const stopMeetingRecording = async () => {
    setStopRecordingLoading(true)
    setLoading(true)
    const apiResp = await stopRecording(resourceId, sid, recordingVideoCallId)
    console.log('stopMeetingRecording', apiResp)
    setSid('')

    setStopRecordingLoading(false)
    if (apiResp?.data?.serverResponse?.extensionServiceState?.length) {
      var payloadResp = apiResp.data.serverResponse.extensionServiceState.find(
        (a: any) => a.serviceName === 'web_recorder_service'
      ).payload.fileList
      console.log('payloadResp', payloadResp)

      if (payloadResp && payloadResp.length !== 0) {
        const fileName = payloadResp.find((a: any) => a.filename.includes('mp4')).filename
        console.log('file name', fileName)

        console.log(
          'file name - full url',
          `https://dcsl-econnect-recordings.s3.eu-west-2.amazonaws.com/meetings/recordings/${fileName}`
        )
        setDownloadUrl(
          `https://dcsl-econnect-recordings.s3.eu-west-2.amazonaws.com/meetings/recordings/${fileName}`
        )
        try {
          const response = await saveMeetingRecordingFileUrl(
            `https://dcsl-econnect-recordings.s3.eu-west-2.amazonaws.com/meetings/recordings/${fileName}`
          )
          console.log('save vid response', response)
        } catch (error) {
          console.log('save vid error', error)
        }
      } else {
        Swal.fire({
          text: `Error saving recording`,
          icon: 'error',
          confirmButtonText: 'Okay',
        }).then(() => {})
      }
    }

    // save this to

    setResourceId('resource')
    setLoading(false)
  }

  useEffect(() => {
    console.log('useEffect users, screenShareVideoCallId', users, screenShareVideoCallId)

    console.log('useEffect screenShareVideoCallId,users', screenShareVideoCallId, users)

    if (screenShareVideoCallId) {
      var screenShareUser = users.find((user) => user?.uid === parseInt(screenShareVideoCallId))
      if (screenShareUser) {
        setRemoteScreenShareUser(screenShareUser)
      }
    } else if (remoteScreenShareUser) {
      console.log('useEffect updating user list', remoteScreenShareUser)

      setRemoteScreenShareUser(undefined)
    }

    console.log('useEffect screenShareUser', screenShareUser)
    console.log('useEffect remoteScreenShareUser', remoteScreenShareUser)
    // setStartScreenShare(!!remoteScreenShareUser)
    // setScreenShareVideoCallId(!remoteScreenShareUser? undefined : screenShareVideoCallId)
  }, [users, screenShareVideoCallId])

  const getSocketConnections = async () => {
    try {
      // delay for 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000))

      const response = await axios.get(GET_CONNECTIONS_URL)
      console.log('getSocketConnections response', response.data.data)
      setSocketConnections(response.data.data)
      const screenShareUser = response.data.data?.find(
        (connection: any) => connection.status === 'SharingScreen'
      )
      console.log('getSocketConnections screenShareUser', screenShareUser)

      if (screenShareUser && screenShareUser.videoCallId !== auth?.user?.videoCallId) {
        console.log('getSocketConnections setScreenShareVideoCallId', screenShareUser)

        setScreenShareVideoCallId(screenShareUser.videoCallId)
        setAlreadyScreenSharing(true)
      }
    } catch (error) {
      console.log('getSocketConnections error', error)
    }
  }

  useEffect(() => {
    getSocketConnections()
  }, [users])

  // send a message to websocket server and wait for response containing an acknowlegement of the message action
  const sendWsMessage = (message: any) => {
    console.log('sendWsMessage message', message)
    if (ws == null) {
      return
    }

    ws.onmessage = (event) => {
      const messageObject = JSON.parse(event.data)
      if (messageObject.action === message.action) {
        console.log('sendWsMessage ws onmessage:' + messageObject.action, event)
        console.log('sendWsMessage clearInterval', messageObject)
        clearInterval(sendFunctionInterval)
        setWsEventHandlers(ws)
      }
    }
    const sendFunctionInterval = setInterval(() => {
      console.log('sendWsMessage sendFunctionInterval', message)

      try {
        ws?.send(JSON.stringify(message))
      } catch (error) {
        console.log('sendWsMessage sendFunctionInterval error', error)
        clearInterval(sendFunctionInterval)
      }
    }, 1000)
  }

  const processWsMessage = async (messageObject: any) => {
    console.log('processWsMessage data', messageObject)

    switch (messageObject.action) {
      case 'screen-share-started':
        console.log('processWsMessage data', messageObject)
        setScreenShareVideoCallId(messageObject.data.videoCallId)
        break
      case 'screen-share-stopped':
        console.log('processWsMessage screen-share-stopped', messageObject)
        console.log('processWsMessage remoteScreenShareUser', remoteScreenShareUser)
        // setUsers((prevUsers) => {
        //   return [...prevUsers.filter((a) => a?.uid !== screenShareVideoCallId), remoteScreenShareUser as IAgoraRTCRemoteUser]
        // })
        setRemoteScreenShareUser(undefined)

        setScreenShareVideoCallId(undefined)

        break
      case 'mute-audio':
      case 'mute-audio-all':
        console.log('processWsMessage mute-audio', messageObject)
        mute('audio')
        break

      default:
        break
    }
  }

  // useEffect(() => {
  //   if (test.action === 'screen-share-started') {
  //     console.log('ws message 2', e.data.action)
  //     setStartScreenShare(true)
  //     var screenShareUser = users.find((user) => user.uid === e.data.videoCallId)
  //     setRemoteScreenShareUser(screenShareUser)
  //   } else if (e.data.action === 'screen-share-stopped') {
  //     setStartScreenShare(true)
  //     setRemoteScreenShareUser(undefined)
  //   }
  // }, [wsMessage])
  //let ws: WebSocket | undefined = undefined

  const setWsEventHandlers = (ws: WebSocket) => {
    ws.onopen = () => {
      // Connection opened
      console.log('WebSocket connection opened')

      console.log('WebSocket connection opened after send')
      //ws.send('Hello, server!'); // Send a message to the server
    }

    ws.onmessage = (e) => {
      // Receive a message from the server
      console.log('ws message data', e.data)
      console.log('ws message data parse', JSON.parse(e.data))
      const messageObject = JSON.parse(e.data)
      if (
        messageObject.action === 'screen-share-started' ||
        messageObject.action === 'screen-share-stopped'
      ) {
        processWsMessage(JSON.parse(e.data))
          .then(() => {
            console.log('processWsMessage done')
          })
          .catch((err) => {
            console.log('processWsMessage err', err)
          })
      }
    }
    ws.onerror = (e) => {
      // An error occurred
      console.log('ws error', e)
      ws.close()
    }
    ws.onclose = (e) => {
      // Connection closed
      console.log('ws disconnected', e)
      console.log(e.code, e.reason)
      setTimeout(function () {
        connectToWS()
      }, 1000)
    }
  }

  const connectToWS = () => {
    console.log('WebSocket connection')
    if (ws && (ws.readyState === ws.OPEN || ws.readyState === ws.CONNECTING)) {
      return
    }

    const webSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_API_URL}/v1?userId=${callUserId}&channelId=${callChannelId}&videoCallId=${callVideoCallId}&name=${callName}`
    )
    setWsEventHandlers(webSocket)
    setWs(webSocket)
  }

  useEffect(() => {
    connectToWS()
  }, [])

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name: string) => {
      console.log('init', name)
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType)
        console.log('subscribe success')
        if (user?.uid === '123456') return

        if (mediaType === 'video') {
          if (users.find((User) => User.uid === user.uid)) {
            return
          }

          const socketUser = socketConnections?.find((a) => a.videoCallId === user.uid)
          if (socketUser) {
            socketUser.videoOn = true
            setSocketConnections((prevSocketConnections) => {
              if (prevSocketConnections) {
                return [
                  ...prevSocketConnections.filter((a) => a.videoCallId !== user.uid),
                  socketUser,
                ]
              }
            })
          }

          setUsers((prevUsers) => {
            return [...prevUsers.filter((a) => a.uid !== user.uid), user]
          })
        }
        if (mediaType === 'audio') {
          user.audioTrack?.play()
        }
      })

      client.on('user-unpublished', (user, type) => {
        console.log('unpublished', user, type)
        if (type === 'audio') {
          user.audioTrack?.stop()
        }
        if (type === 'video') {
          // setUsers((prevUsers) => {
          //   return prevUsers.filter((User) => User.uid !== user.uid)
          // })
          const socketUser = socketConnections?.find((a) => a.videoCallId === user.uid)
          if (socketUser) {
            socketUser.videoOn = false
            setSocketConnections((prevSocketConnections) => {
              if (prevSocketConnections) {
                return [
                  ...prevSocketConnections.filter((a) => a.videoCallId !== user.uid),
                  socketUser,
                ]
              }
            })
          }
        }
      })

      client.on('user-left', (user) => {
        console.log('leaving', user)
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid)
        })
      })
      console.log('uid val before join', callVideoCallId)
      console.log('uid val before join type', typeof callVideoCallId)
      const userUid = await client.join(appId, name, token, Number(callVideoCallId))
      console.log('uid val after join', userUid)

      setVideoCallId(userUid as number)

      // if (tracks) await client.publish([tracks[0], screenShareTracks as ILocalTrack,]);
      if (callUserId === '123456') {
      } else if (tracks) await client.publish([tracks[0], tracks[1]])
      // await mute('video')
      await getSocketConnections()
      setStart(true)
    }

    if (ready && tracks) {
      console.log('init ready')
      init(callChannelId)
    }

    return () => {
      leaveChannel()
    }
  }, [callChannelId, client, ready, tracks])

  const mute = async (type: 'audio' | 'video') => {
    if (tracks === null) return

    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio)
      setTrackState((ps) => {
        return {...ps, audio: !ps.audio}
      })
    } else if (type === 'video') {
      await tracks[1].setEnabled(!trackState.video)

      // if(!trackState.video){

      //   if (client.connectionState === 'CONNECTED') {
      //     await client.publish(tracks)
      //   } else {
      //     //client.join(appId, callChannelId, token, Number(callVideoCallId))
      //   }
      //   // await client.publish(
      //   //   [tracks[0], tracks[1]].filter((track) => track.enabled)
      //   // )
      // }

      setTrackState((ps) => {
        return {...ps, video: !ps.video}
      })
    }
  }

  const leaveChannel = async () => {
    if (tracks === null) return

    await client.leave()
    client.removeAllListeners()
    tracks[0].close()
    tracks[1].close()
    setStart(false)
    setInCall(false)
  }

  return (
    <div className='App card card-body'>
      <>
        {callUserId !== '---' && (
          <div className=' py-6 mb-0 bg-white' id='kt_toolbar'>
            {/* begin::Container */}
            <div
              id='kt_toolbar_container'
              className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack container')}
            >
              <h3>Call Meeting</h3>

              {/* begin::Actions */}
              <div className='d-flex align-items-center py-1'>
                <button
                  onClick={() =>
                    leaveChannel().then(() => {
                      setInCall(false)
                      setInlineCall(false)
                      // window.open('about:blank', '_self')
                      // window.close()
                    })
                  }
                  className='btn btn-danger btn-sm fs-7 me-3 py-2'
                >
                  Leave Call
                </button>
                {/* <button
                  onClick={() =>
                    {
                      setInlineCall(false)
                      setInCall(true)

                    }
                  }
                  className='btn btn-default btn-sm fs-7 me-3 py-2'
                >
                  Pop
                </button> */}
                {/* 
            <button className='border-danger btn btn-outline btn-outline-danger btn-sm fs-7 me-3 py-2'>
              <i className='fa fa-record-vinyl fs-4 me-1'></i>
              Record Call
            </button> */}
                {sid ? (
                  <button
                    className='border-danger btn btn-outline btn-outline-danger btn-sm fs-7 me-3 py-2'
                    onClick={async () => {
                      if (!stopRecordingLoading) {
                        await stopMeetingRecording()
                      }
                    }}
                  >
                    {stopRecordingLoading ? (
                      <div className='me-2 spinner-border spinner-border-sm' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    ) : null}
                    <i className='fa fa-record-vinyl fs-4 me-1 fa-fade'></i>
                    Stop Recording
                  </button>
                ) : (
                  <button
                    className='border-default btn btn-outline btn-outline-default btn-sm fs-7 me-3 py-2'
                    disabled={loading}
                    onClick={async () => {
                      await startMeetingRecording()
                    }}
                  >
                    <i className='fa fa-record-vinyl fs-4 me-1'></i>
                    Start Recording
                    {loading && <i className='fa fa-spinner fa-spin'></i>}
                  </button>
                )}

                {downloadUrl ? (
                  <button
                    className='border-danger btn btn-outline btn-outline-danger btn-sm fs-7 me-3 py-2'
                    onClick={async () => {
                      window.open(downloadUrl, '_blank')
                    }}
                  >
                    Download Video
                  </button>
                ) : null}
                <button
                  onClick={() => mute('audio')}
                  className='btn btn-white btn-icon btn-outline btn-sm fs-7 me-3 py-2'
                >
                  <i
                    className={`fa fa-microphone${trackState.audio ? '' : '-slash'} fs-4 me-1`}
                  ></i>
                </button>
                <button
                  onClick={() => mute('video')}
                  className='btn btn-white btn-icon btn-outline btn-sm fs-7 me-3 py-2'
                >
                  <i className={`fa fa-video${trackState.video ? '' : '-slash'} fs-4 me-1`}></i>
                </button>
                {ws && (
                  <button
                    onClick={async () => {
                      setLoading(true)
                      if (startScreenShare) {
                        sendWsMessage({
                          action: 'screen-share-stopped',
                          data: {
                            channelId: callChannelId,
                            userId: callUserId,
                            videoCallId: callVideoCallId,
                          },
                        }) // Send a message to the server
                        // delay for 1 second
                        await new Promise((resolve) => setTimeout(resolve, 3000))

                        console.log('screenShare onClick stop screen share')
                        console.log('screenShare onClick tracks', tracks)
                        if (tracks) {
                          await client.unpublish()
                          await client.publish(
                            [tracks[0], tracks[1]].filter((track) => track.enabled)
                          )
                        }
                      } else {
                        console.log('screenShare onClick start screen share')
                      }
                      setStartScreenShare(!startScreenShare)
                      setLoading(false)
                    }}
                    className='btn btn-primary btn-sm fs-7 me-3 py-2'
                    disabled={loading && !screenShareVideoCallId}
                  >
                    {`${startScreenShare ? 'Stop Sharing Screen' : 'Share Screen'}`}
                    {loading && <i className='fa fa-spinner fa-spin'></i>}
                  </button>
                )}
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Container */}
          </div>
        )}

        <CreateAppModal
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
        />
      </>
      {start && tracks && (
        <Videos
          ws={ws as WebSocket}
          remoteScreenShareUser={remoteScreenShareUser}
          startScreenShare={startScreenShare}
          client={client}
          users={users}
          tracks={tracks}
          videoCallId={videoCallId as number}
          socketConnections={socketConnections as Array<any>}
          screenShareVideoCallId={screenShareVideoCallId}
          sendWsMessage={sendWsMessage}
        />
      )}
    </div>
  )
}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[]
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack]
  client: any
  remoteScreenShareUser: IAgoraRTCRemoteUser | undefined
  startScreenShare: boolean
  ws: WebSocket
  videoCallId: number
  socketConnections: Array<any>
  screenShareVideoCallId: string | undefined
  sendWsMessage: (message: any) => void
}) => {
  const {
    users,
    tracks,
    client,
    remoteScreenShareUser,
    startScreenShare,
    videoCallId,
    socketConnections,
    screenShareVideoCallId,
  } = props
  const [remoteUsers, setRemoteUsers] = useState(users)
  const {auth} = useAuth()

  useEffect(() => {
    console.log('Videos users changed', users)
    setRemoteUsers([])
    // 1 second delay
    setTimeout(() => {
      setRemoteUsers([...users])
    }, 10)
  }, [users, tracks, JSON.stringify(socketConnections)])

  return (
    <div>
      <div className='row'>
        <div className='col-12'>
          {screenShareVideoCallId && !startScreenShare && remoteScreenShareUser?.hasVideo && (
            <RemoteScreenShareMediaPlayer
              user={socketConnections?.find((a) => a.videoCallId == remoteScreenShareUser.uid)}
              client={client}
              trackType='local'
              videoTrack={remoteUsers.find((a) => a.uid === screenShareVideoCallId)?.videoTrack}
              audioTrack={remoteScreenShareUser.audioTrack}
              screenShare={true}
              ws={props.ws}
              videoCallId={videoCallId}
              agoraRemoteUser={remoteScreenShareUser}
            ></RemoteScreenShareMediaPlayer>
          )}
          {startScreenShare && (
            <LocalScreenShareMediaPlayer
              user={auth?.user}
              localAudioTrack={tracks[0]}
              localVideoTrack={tracks.length > 1 ? tracks[1]:undefined}
              client={client}
              trackType='local'
              screenShare={true}
              ws={props.ws}
              videoCallId={videoCallId}
            ></LocalScreenShareMediaPlayer>
          )}
        </div>
      </div>
      <div className=' row' id='videos'>
        {videoCallId !== 123456 && (
          <MediaPlayer
            user={{name: 'Me', videoCallId: videoCallId}}
            client={client}
            trackType='local'
            videoTrack={true ? tracks[1] : tracks[1]}
            audioTrack={undefined}
            screenShare={startScreenShare}
            userCount={remoteUsers.length}
            sendWsMessage={props.sendWsMessage}
            userTrackState={undefined}

          ></MediaPlayer>
        )}

        {/* <AgoraVideoPlayer className='vid rounded overflow-hidden bg-danger col' videoTrack={tracks[1]} style={{height: '95%', width: '95%'}} /> */}
        {remoteUsers.length > 0 &&
          remoteUsers
            .filter((a) => a.uid !== screenShareVideoCallId)
            .map((user) => {
              return (
                <MediaPlayer
                  user={socketConnections?.find((a) => Number(a.videoCallId) == user.uid)}
                  client={client}
                  agoraRemoteUser={user}
                  trackType='remote'
                  videoTrack={user?.videoTrack}
                  audioTrack={user.audioTrack}
                  screenShare={startScreenShare}
                  userCount={remoteUsers.length}
                  sendWsMessage={props.sendWsMessage}
                  userTrackState={undefined}
                ></MediaPlayer>
                // <div className=' col'>
                // <span>{user.hasAudio.toString()}hjh</span>
                // <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} style={{height: '95%', width: '95%'}} key={user.uid} />

                // </div>
              )
            })}
      </div>
    </div>
  )
}
