import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function ApprovalUsers() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {currentUser} = useAuth()
  console.log('currentUser', currentUser?.companyId)
  const {id} = useParams()

  const companyId = currentUser?.companyId

  const formObject = {
    listEndpoint: `${API_URL}/approval-request/${id}/user-response`,
    listTitle: 'Members',
    listFields: [
      {
        name: 'userName',
        header: 'Board Member',
        type: 'link',
        path: `/approvals/member`,
      },

      {
        name: 'dateCreated',
        header: 'Date Added',
        type: 'date',
      },
      // {
      //   name: 'status',
      //   header: 'Status',
      //   type: 'status',
      // },
    ],
    addEndpoint: `${API_URL}/approval-request/${id}/user-response`,
    addTitle: 'Request Approval',
    addFields: [
      {
        name: 'userId',
        label: 'Board Member',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
      },
    ],
    updateEndpoint: `${API_URL}/approval-request-user-response`,
    updateTitle: 'Review Request',
    updateFields: [
      {
        name: 'status',
        label: 'Status',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Disapproved', title: 'Disapprove'},
          {id: 'Request Rework', title: 'Request Rework'},
          {id: 'Approved', title: 'Approve'},
        ],
      },
      {
        name: 'reason',
        label: 'Reason',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
