import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {BoardMemberDetails} from './components/BoardMemberDetails'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BoardMembersPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/company-user/${id}`,
    dataFields: [
      // {
      //   name: 'userRole',
      //   label: 'User Role',
      // },
    ],
    tabs: [
      {
        title: 'Overview',
        path: `/board-members/${id}`,
      },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        {/* <Route element={<Navigate to=':id' />} /> */}
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <BoardMemberDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default BoardMembersPage
