import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Evaluations() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id

  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/evaluation`,
    listTitle: 'Evaluation',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/admin/evaluations',
      },
      {
        name: 'type',
        header: 'Type',
      },
      // {
      //   name: 'description',
      //   header: 'Description',
      // },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'date',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/company/${currentTenant}/evaluation`,
    addTitle: 'Add Evaluation',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
      {
        name: 'type',
        label: 'Type',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Regular', title: 'Regular'},
          {id: 'Board Effectiveness', title: 'Board Effectiveness'},
          {id: 'Peer Review', title: 'Directors Peer Review'},
          {id: 'Chairman Review', title: 'Chairman\'s Leadership Assessment'},
          {id: 'Secretary Review', title: 'Company Secretary\'s Performance Assessment'},
        ],
      },
    ],
    updateEndpoint: `${API_URL}/evaluation`,
    updateTitle: 'Edit Evaluation',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
      {
        name: 'type',
        label: 'Type',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Regular', title: 'Regular'},
          {id: 'Board Effectiveness', title: 'Board Effectiveness'},
          {id: 'Peer Review', title: 'Directors Peer Review'},
          {id: 'Chairman Review', title: 'Chairman\'s Leadership Assessment'},
          {id: 'Secretary Review', title: 'Company Secretary\'s Performance Assessment'},
        ],
      },
      {
        name: 'status',
        label: 'Status',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          {id: 'Disabled', title: 'Disabled'},
          {id: 'Enabled', title: 'Enabled'},
        ],
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
