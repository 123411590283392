import {ManageGroupItem, ManageItem} from '../../../web-app-core'
import {useParams} from 'react-router-dom'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const PollQuestions = () => {
  const {id} = useParams()

  const formObject = {
    listEndpoint: `${BASE_URL}/poll/${id}/question`,
    listTitle: 'Poll Questions',
    listFields: [
      {
        name: 'title',
        header: 'Question',
        // type: 'link',
        // path: '/polls',
      },
      {
        name: 'type',
        header: 'Type',
      },
      {
        name: 'optionA',
        header: 'Option A',
      },
      {
        name: 'optionB',
        header: 'Option B',
      },
      {
        name: 'optionC',
        header: 'Option C',
      },
      {
        name: 'optionD',
        header: 'Option D',
      },
      {
        name: 'optionE',
        header: 'Option E',
      },
    ],
    addEndpoint: `${BASE_URL}/poll/${id}/question`,
    addTitle: 'Add Question to Poll',
    addFields: [
      {
        name: 'title',
        label: 'Question',
        toolTip: 'Enter Title',
        placeholder: '',
        validation: {
          type: 'string',
          required: true,
        },
        type: 'text',
      },
      {
        name: 'type',
        label: 'Question Type',
        toolTip: 'Select the question type',
        placeholder: '',
        validation: {
          required: true,
        },
        type: 'dropdown',
        options: [
          {id: 'Multichoice', title: 'Multichoice'},
          {id: 'Free Text', title: 'Free Text'},
        ],
      },
      {
        name: 'optionA',
        label: 'Option A',
        toolTip: 'Enter Option A',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionB',
        label: 'Option B',
        toolTip: 'Enter Option B',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionC',
        label: 'Option C',
        toolTip: 'Enter Option C',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionD',
        label: 'Option D',
        toolTip: 'Enter Option D',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionE',
        label: 'Option E',
        toolTip: 'Enter Option E',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
      },
    ],
    updateEndpoint: `${BASE_URL}/poll-question`,
    updateTitle: 'Update Question',
    updateFields: [
      {
        name: 'title',
        label: 'Question',
        toolTip: 'Enter Title',
        placeholder: '',
        validation: {
          type: 'string',
          required: true,
        },
        type: 'text',
      },
      {
        name: 'type',
        label: 'Question Type',
        toolTip: 'Select the question type',
        placeholder: '',
        validation: {
          required: true,
        },
        type: 'dropdown',
        options: [
          {id: 'Multichoice', title: 'Multichoice'},
          {id: 'Free Text', title: 'Free Text'},
        ],
      },
      {
        name: 'optionA',
        label: 'Option A',
        toolTip: 'Enter Option A',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionB',
        label: 'Option B',
        toolTip: 'Enter Option B',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionC',
        label: 'Option C',
        toolTip: 'Enter Option C',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionD',
        label: 'Option D',
        toolTip: 'Enter Option D',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
        cols: 6,
      },
      {
        name: 'optionE',
        label: 'Option E',
        toolTip: 'Enter Option E',
        placeholder: '',
        validation: {type: 'string'},
        type: 'text',
      },
    ],
  }

  return (
    <div className='card card-flush'>
      <ManageItem formObject={formObject} />
    </div>
  )
}

export default PollQuestions
