import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CompanyUsers from './components/CompanyUsers'
import MeetingCall from './components/MeetingCall'
import MeetingAgenda from './tabs/meetingAgenda/MeetingAgenda'
import Meetings from './Meetings'
import MeetingMessaging from './tabs/MeetingMessaging'
import MeetingNotes from './tabs/meetingNotes/MeetingNotes'
import MeetingDocuments from './tabs/MeetingDocuments/MeetingDocuments'
import MeetingDocument from './tabs/MeetingDocuments/MeetingDocument'
import MeetingRecordings from './tabs/meetingRecordings/MeetingRecordings'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MeetingPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/meeting/${id}`,
    dataFields: [
      {
        name: 'startTime',
        label: 'Start',
        type: 'datetime',
      },
      {
        name: 'endTime',
        label: 'End',
        type: 'datetime',
      },
      {
        name: 'dateCreated',
        label: 'Date Created',
        type: 'date',
      },
    ],
    tabs: [
      {
        title: 'Call',
        path: `/meetings/${id}`,
      },
      {
        title: 'Chat',
        path: `/meetings/${id}/chat`,
      },
      {
        title: 'Agenda',
        path: `/meetings/${id}/agenda`,
      },
      {
        title: 'Notes',
        path: `/meetings/${id}/notes`,
      },
      {
        title: 'Documents',
        path: `/meetings/${id}/documents`,
      },
      {
        title: 'Recordings',
        path: `/meetings/${id}/recordings`,
      },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={id as string} />
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Call</PageTitle>
              <MeetingCall />
            </>
          }
        />
        <Route
          path={`agenda`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Agenda</PageTitle>
              <MeetingAgenda />
            </>
          }
        />
        <Route
          path={`chat`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Chat</PageTitle>
              <MeetingMessaging />
            </>
          }
        />
        <Route
          path={`notes`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Chat</PageTitle>
              <MeetingNotes />
            </>
          }
        />
        <Route
          path={`documents`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <MeetingDocuments />
            </>
          }
        />
        <Route
          path={`recordings`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Chat</PageTitle>
              <MeetingRecordings />
            </>
          }
        />
        <Route
          path={`documents/:id`}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Documents</PageTitle>
              <MeetingDocument />
            </>
          }
        />

        <Route element={<Navigate to='/crafted/pages/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default MeetingPage
