import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const GET_REQUEST = `${API_URL}/user-notification`

function getRequest(id: string) {
  return axios.get<any>(`${GET_REQUEST}/${id}`)
}

export function NotificationDetails() {
  const {id} = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [request, setRequest] = useState({} as any)

  const loadData = () => {
    setLoading(true)
    getRequest(id as string).then(({data}) => {
      console.log('response data', data)
      setRequest(data?.data)
    })
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      {/* biodata section */}
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Notifcation Details</h3>
          </div>

          {/* <button
            data-testid='update-modal-btn'
            className='btn btn-primary align-self-center'
            data-bs-toggle='modal'
            data-bs-target='#kt_update_profile'
          >
            View
          </button> */}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Title</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>{request?.title}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'> Description</label>

            <div className='col-lg-8'>
              <span className='fw-semibold text-gray-800 fs-6'>{request?.description}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'> Status</label>

            <div className='col-lg-8'>
              <span className='fw-semibold text-gray-800 fs-6'>{request?.status}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
