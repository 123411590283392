import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'


const API_URL = process.env.REACT_APP_API_URL

export default function Compliance() {

  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id;

  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/compliance`,
    listTitle: 'Compliance',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/compliance',
      },
      // {
      //   name: 'description',
      //   header: 'Description',
      // },
      {
        name: 'startTime',
        header: 'Start Date',
        type: 'datetime',
      },
      {
        name: 'endTime',
        header: 'Completion Date',
        type: 'datetime',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/company/${currentTenant}/compliance`,
    addTitle: 'Add Compliance',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
			{
				name: 'startTime',
				label: 'Start Date',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
			{
				name: 'endTime',
				label: 'Completion Date',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
    ],
    updateEndpoint: `${API_URL}/compliance`,
    updateTitle: 'Edit Compliance',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
			{
				name: 'startTime',
				label: 'Start Date',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
			{
				name: 'endTime',
				label: 'Completion Date',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
      {
        name: 'status',
        label: 'Status',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          { id: 'Not Started', title: 'Not Started' },
          { id: 'Ongoing', title: 'Ongoing' },
          { id: 'Completed', title: 'Completed' },
        ],
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
