import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {NotificationDetails} from './tabs/NotificationDetails'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const NotificationsPage = () => {
  const {id} = useParams()
  const formObject = {
    endpoint: `${API_URL}/user-notification/${id}`,
    dataFields: [],
    tabs: [],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <DetailHeader formObject={formObject} id={id as string} /> */}
            <Outlet />
          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <NotificationDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default NotificationsPage
