import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function CalendarEvents() {
  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()

  const currentTenant = auth?.currentTenant?.id

  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/calendar-event`,
    listTitle: 'Calendar Events',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/calendar',
      },
      {
        name: 'startTime',
        header: 'Start',
        type: 'datetime',
      },
      {
        name: 'endTime',
        header: 'End',
        type: 'datetime',
      },
    ],
    addEndpoint: `${API_URL}/company/${currentTenant}/calendar-event`,
    addTitle: 'Add Calendar Event',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
      {
        name: 'startTime',
        label: 'Start',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '6',
      },
      {
        name: 'endTime',
        label: 'End',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '6',
      },
      {
        name: 'location',
        label: 'Location',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'text',
      },
    ],
    updateEndpoint: `${API_URL}/calendar-event`,
    updateTitle: 'Update Calendar Event',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'textarea',
      },
      {
        name: 'startTime',
        label: 'Start',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '6',
      },
      {
        name: 'endTime',
        label: 'End',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'datetimepicker',
        cols: '6',
      },
      {
        name: 'location',
        label: 'Location',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'text',
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
