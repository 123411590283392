import {ManageItem, useAuth} from '../../../web-app-core'
import {useParams} from 'react-router-dom'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const EvaluationUserRespondentUsers = () => {
  const {currentUser} = useAuth()
  const companyId = currentUser?.companyId

  const {id, evaluationUserId} = useParams()

  const formObject = {
    listEndpoint: `${BASE_URL}/evaluation-user/${evaluationUserId}/respondent-user`,
    listTitle: 'Respondents',
    
    listFields: [
      {
        name: 'respondentUser.firstName',
        header: 'First Name',
        type: 'link',
        path: 'response',
      },
      {
        name: 'respondentUser.lastName',
        header: 'Last Name',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'link',
        path: 'response',
      },
    ],
  }

  return (
    <div className='card card-flush my-5'>
      <ManageItem formObject={formObject} />
    </div>
  )
}

export default EvaluationUserRespondentUsers
