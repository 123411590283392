import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Companies() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  console.log('pathname', pathname)
  const companyId = ''

  const formObject = {
    listEndpoint: `${API_URL}/user/company`,
    listTitle: 'Please select company to continue',
    listFields: [
      {
        name: 'company.name',
        header: 'COMPANY',
        type: 'link',
        path: '/select-company',
      },

      {
        name: 'boardRole',
        header: 'BOARD ROLE',
      },
      {
        name: 'userRole',
        header: 'USER ROLE',
      },
    ],
  }

  return (
    <>
      <div className='w-50 mx-auto pt-20'>
        <ManageItem formObject={formObject} />
      </div>
    </>
  )
}
