import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom'
import {submitFormData} from '../../helpers/utils/SubmitFormData'
import {PasswordMeterComponent} from '../../assets/ts/components'
import AppForm from './AppForm'

interface Props {
  formObject?: any
}

interface LocationState {
  email: string
}

const AppFormWizard = ({formObject}: Props) => {
  const [loadResend, setLoadResend] = useState(false)
  const {id} = useParams()
  const navigate = useNavigate()

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questions, setQuestions] = useState<any[]>(formObject.questions)

  useEffect(() => {
    try {
      getQuestions()
    } catch (error) {
      console.log('getQuestions error', error)
    }
  }, [])

  const getQuestions = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/evaluation/${formObject.id}/question`)
    console.log('getQuestions response', response)
    const formattedQuestions = formatDataToFormFields(response.data.data)
    setQuestions(formattedQuestions)
    console.log('getQuestions formattedQuestions', formattedQuestions)
  }

  const setNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const formatDataToFormFields = (data: any) => {
    return data.map((question: any) => {
      return {
        formFields: [
          {
            name: question.id,
            label: question.title,
            placeholder: question.title,
            validation: {
              required: true,
            },
            type: 'multichoice',
            options: [
              {
                title: question.optionA,
                id: question.optionA,
              },
              {
                title: question.optionB,
                id: question.optionB,
              },
              {
                title: question.optionC,
                id: question.optionC,
              },
              {
                title: question.optionD,
                id: question.optionD,
              },
              {
                title: question.optionE,
                id: question.optionE,
              },
            ],
          },
        ],
      }
    })
  }

  if (!id) {
    // navigate('/')
  }

  const [loading, setLoading] = useState(false)

  const submitData = async (values: any) => {
    if(currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      return
    }
    const submitData = {
      evaluationUserId: formObject.evaluationUserId,
      answer: values
    }
    console.log('values to save', submitData)
    //return

   
    setLoading(true)

    let response = await submitFormData(
      {
        formFields: [{ name: "evaluationUserId" },{ name: "answer" }],
        addEndpoint: `${process.env.REACT_APP_API_URL}/evaluation-respondent-user/${formObject.respondentUserId}/submit-response`,
      }, submitData)

    console.log('submitData response', response)
    setLoading(false)

    if (response?.data.success) {
      submitted(response?.data)
    } else {
      switch (response?.data?.error?.code) {
        case 'UsernameExistsException':
          break
        case 'UserNotConfirmedException':
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          }).then(({isConfirmed, isDismissed}) => {
            // navigate(formObject?.userExistUrl, {state: {email: values['emailAddress']}})
            return
          })
          break

        default:
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          })
          break
      }
    }
  }

  const submitted = (response: any) => {
    Swal.fire({
      text: response?.message,
      icon: 'success',
      confirmButtonText: 'Okay',
    }).then(() => {
       navigate(formObject.redirectPath)
    })
  }

  return (
    questions?.length ? (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'></div>
      </div>
      <div className='py-10 px-10'>
        <div className=''>
          <div className='text-start'>
            <h3 className='text-dark mb-3 fw-bold fw-bolder fs-3' data-kt-translate='sign-in-title'>
              <span className='text-capitalize'></span> {formObject.title}: Evaluation response: {currentQuestionIndex + 1}{' '} of {questions.length} 
            </h3>
            <div className='text-gray-400 fw-semibold fs-6' data-kt-translate='general-desc'>
              {formObject?.description}
            
            </div>
          </div>
        </div>
        <div className='pt-10 pa-10'>
          {questions[currentQuestionIndex] && (
            <AppForm
              formObject={questions[currentQuestionIndex]}
              onSubmit={submitData}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>): null
  )
}

export default AppFormWizard
