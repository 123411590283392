import {useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function ComplianceChecklist() {
  const navigate = useNavigate()

  const {pathname} = useLocation()
  const {id} = useParams()
  console.log('pathname', pathname)

  const {currentUser} = useAuth()
  console.log('currentUser', currentUser?.companyId)

  const companyId = currentUser?.companyId;


  const formObject = {
    listEndpoint: `${API_URL}/compliance/${id}/checklist-item`,
    listTitle: 'Checklist Items',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/compliance-checklist-item',
      },
      {
        name: 'assignedUser.firstName',
        header: 'Assigned',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/compliance/${id}/checklist-item`,
    addTitle: 'Add Checklist Item',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
      {
        name: 'assignedUserId',
        label: 'Assign',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'dropdown',
        optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
      },
    ],
    updateEndpoint: `${API_URL}/compliance-checklist-item`,
    updateTitle: 'Edit Checklist Item',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
      {
        name: 'assignedUserId',
        label: 'Assign',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
        },
        type: 'dropdown',
        optionsEndpoint: `${API_URL}/company/${companyId}/user?limit=1000`,
      },
      {
        name: 'status',
        label: 'Status',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          { id: 'Not Started', title: 'Not Started' },
          { id: 'Ongoing', title: 'Ongoing' },
          { id: 'Completed', title: 'Completed' },
        ],
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
