/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../../web-app-core'

export function AsideMenuMain() {
  const intl = useIntl()
  // get user from context
  const {currentUser} = useAuth()
console.log('AsideMenuMain currentUser', currentUser)
  return (
    <>
      <AsideMenuItem title='Meetings' to='meetings' />
      <AsideMenuItem title='Action Plans' to='action-plans' />
      <AsideMenuItem title='Training' to='trainings' />
      <AsideMenuItem title='Polls' to='polls' />
     {/* { currentUser?.userRole === 'Admin' && <AsideMenuItem title='Evaluation Admin' to='admin/evaluations' />} */}
      <AsideMenuItem title='Evaluation Admin' to='admin/evaluations' />
      <AsideMenuItem title='Evaluation' to='evaluations' />
      <AsideMenuItem title='Compliance' to='compliance' />
      <AsideMenuItem title='Members' to='board-members' />
      <AsideMenuItem title='Companies' to='companies' />
      <AsideMenuItem title='Approvals' to='approvals' />
      <AsideMenuItem title='Calendar' to='calendar' />
      <AsideMenuItem title='Document Folders' to='document-folders' />
      <AsideMenuItem title='Notifications' to='notifications' />
    </>
  )
}
