/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useAuth} from '../../../web-app-core'
import ReactTimeAgo from 'react-time-ago'

type Props = {
  isDrawer?: boolean
  channelId: string
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({isDrawer = false, channelId}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [messages, setMessages] = useState<any[]>([])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [latestMessageTimestamp, setLatestMessageTimestamp] = useState<number>(0)
  const [firstLoadMessageTimestamp, setFirstLoadMessageTimestamp] = useState<number>(0)
  const [ws, setWs] = useState<WebSocket | undefined>()

  const {currentUser} = useAuth()

  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>()
  const API_URL = process.env.REACT_APP_API_URL
  console.log('ChatInner channelId', channelId)

  const messagesEndRef = useRef<any>()
  const messagesParentRef = useRef<any>()

  const processWsMessage = async (messageObject: any) => {
    console.log('processWsMessage data', messageObject)

    switch (messageObject.action) {
      case 'chat-message-added':
        console.log('processWsMessage data', messageObject)
        messages.push({
          ...messageObject.data,
          text: messageObject.data.text,
          type: messageObject.data?.senderUserId === currentUser?.id ? 'out' : 'in',
          dateCreated: messageObject.data.dateCreated,
        })
        setMessages([...messages])
        toggleChatUpdateFlat(!chatUpdateFlag)

        setLatestMessageTimestamp(new Date().getTime())
        break

      default:
        break
    }
  }

  const setWsEventHandlers = (ws: WebSocket) => {
    ws.onopen = () => {
      // Connection opened
      console.log('WebSocket connection opened')

      console.log('WebSocket connection opened after send')
      //ws.send('Hello, server!'); // Send a message to the server
    }

    ws.onmessage = (e) => {
      // Receive a message from the server
      console.log('setWsEventHandlers ws message data', e.data)
      console.log('setWsEventHandlers ws message data parse', JSON.parse(e.data))
      const messageObject = JSON.parse(e.data)
      if (messageObject.action === 'chat-message-added') {
        processWsMessage(JSON.parse(e.data))
          .then(() => {
            console.log('processWsMessage done')
          })
          .catch((err) => {
            console.log('processWsMessage err', err)
          })
      }
    }
    ws.onerror = (e) => {
      // An error occurred
      console.log('error', e)
    }
    ws.onclose = (e) => {
      // Connection closed
      console.log(e.code, e.reason)
    }
  }

  useEffect(() => {
    console.log('WebSocket connection')
    const ws = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_API_URL}/v1?userId=${currentUser?.id}&channelId=${channelId}&mode=chat`
    )
    setWsEventHandlers(ws)
    setWs(ws)
  }, [])

  const scrollToBottom = () => {
    if (!messagesEndRef || !messagesEndRef.current) return
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
  }

  useEffect(() => {
    scrollToBottom()
  }, [latestMessageTimestamp, messagesParentRef.current?.scrollHeight])

  useEffect(() => {
    messagesParentRef.current.scrollTop = messagesParentRef.current.scrollHeight
  }, [firstLoadMessageTimestamp])

  const getMessages = async (inputLastEvaluatedKey?: any) => {
    setLoading(true)
    try {
      const response = await axios.get(`${API_URL}/message/channel/${channelId}`)
      console.log('getMessages response', response)
      if ((messages && messages[0] && !messages[0].channelId) || !inputLastEvaluatedKey) {
        if (messages) messages.length = 0
      }
      let loadedMessages = response?.data?.data.map((message: any) => {
        return {
          ...message,
          type: message?.senderUserId === currentUser?.id ? 'out' : 'in',
          time: 'Just now',
        }
      })

      //  if(!messages) return

      messages.push(...loadedMessages.reverse())
      setMessages([...messages])

      console.log('messages', messages)

      //setItems(response?.data?.data);
      setLastEvaluatedKey(response?.data?.lastEvaluatedKey)
      setFirstLoadMessageTimestamp(new Date().getTime())
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log('ereqd', err)
      toast.error('err', err?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    // get messages from server
  })

  useEffect(() => {
    if (channelId && (!messages || !messages[0]?.channelId)) {
      console.log('useEffect channelId', channelId)
      getMessages()
    }
  }, [channelId])

  const sendMessage = async () => {
    const newMessage: any = {
      text: message,
    }
    // const newMessage: any = {
    //   user: 2,
    //   type: 'out',
    //   text: message,
    //   time: 'Just now',
    // }

    const response = await axios.post(`${API_URL}/message/channel/${channelId}`, newMessage)
    console.log('sendMessage response', response)

    // bufferMessages.push(newMessage)
    setMessages([...messages, newMessage])
    // toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')

    setLatestMessageTimestamp(new Date().getTime())
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', {'h-400px ': !isDrawer})}
        ref={messagesParentRef}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messages?.map((message, index) => {
          const userInfo = message.senderUser
          const state = message.type === 'in' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.type === 'in' ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.type === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <i className='fa fa-user'></i>
                      </div>
                      <div className='ms-3'>
                        <Link
                          to='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                        >
                          {userInfo?.firstName} {userInfo?.lastName}
                        </Link>
                        <span className='text-muted fs-7 mb-1'>
                          {message?.dateCreated && (
                            <ReactTimeAgo date={new Date(message?.dateCreated)} locale='en-US' />
                          )}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>
                          {userInfo?.dateCreated}
                          {message?.dateCreated && (
                            <ReactTimeAgo date={new Date(message?.dateCreated)} locale='en-US' />
                          )}
                        </span>
                        <Link
                          to='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          You
                        </Link>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <i className='fa fa-user'></i>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{__html: message.text}}
                ></div>
              </div>
            </div>
          )
        })}
        <div ref={messagesEndRef} />
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export {ChatInner}
