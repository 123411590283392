import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, TableList, useAuth} from '../../../web-app-core'


const API_URL = process.env.REACT_APP_API_URL

export default function Trainings() {

  const {pathname} = useLocation()
  console.log('pathname', pathname)

  const {auth} = useAuth()
  console.log('currentTenant', auth?.currentTenant?.id)

  const currentTenant = auth?.currentTenant?.id;

  const formObject = {
    listEndpoint: `${API_URL}/company/${currentTenant}/training`,
    listTitle: 'Training',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/trainings',
      },
      {
        name: 'startTime',
        header: 'Start',
        type: 'datetime',
      },
      {
        name: 'endTime',
        header: 'End',
        type: 'datetime',
      },
      {
        name: 'dateCreated',
        header: 'Date Created',
        type: 'date',
      },
      {
        name: 'status',
        header: 'Status',
        type: 'status',
      },
    ],
    addEndpoint: `${API_URL}/company/${currentTenant}/training`,
    addTitle: 'Add Training',
    addFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
			{
				name: 'startTime',
				label: 'Start',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
			{
				name: 'endTime',
				label: 'End',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
    ],
    updateEndpoint: `${API_URL}/training`,
    updateTitle: 'Edit Training',
    updateFields: [
      {
        name: 'title',
        label: 'Title',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
          minCharacters: 2,
        },
        type: 'text',
      },
      {
        name: 'description',
        label: 'Description',
        toolTip: '',
        placeholder: '',
        validation: {
          type: 'string',
          minCharacters: 2,
        },
        type: 'textarea',
      },
			{
				name: 'startTime',
				label: 'Start',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
			{
				name: 'endTime',
				label: 'End',
				toolTip: '',
				placeholder: '',
				validation: {
          required: true,
					type: 'string',
				},
				type: 'datetimepicker',
				cols: '5',
			},
      {
        name: 'status',
        label: 'Status',
        toolTip: '',
        placeholder: '',
        validation: {
          required: true,
          type: 'string',
        },
        type: 'dropdown',
        options: [
          { id: 'Pending', title: 'Pending' },
          { id: 'Active', title: 'Active' },
          { id: 'Completed', title: 'Completed' },
          { id: 'Canceled', title: 'Canceled' },
        ],
      },
    ],
  }

  return <ManageItem formObject={formObject} />
}
