import {Navigate, Routes, Route, Outlet, useParams} from 'react-router-dom'
import {DetailHeader} from '../../../web-app-core'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Profile} from './components/Profile'
import {useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => {
  const {currentUser} = useAuth()
  const formObject = {
    endpoint: `${API_URL}/company-user/${currentUser?.id}`,
    dataFields: [
      // {
      //   name: 'userRole',
      //   label: 'User Role',
      // },
    ],
    tabs: [
      // {
      //   title: 'Overview',
      //   path: `/${id}`,
      // },
    ],
  }
  return (
    <Routes>
      <Route
        element={
          <>
            <DetailHeader formObject={formObject} id={currentUser?.id as string} />
            <Outlet />
          </>
        }
      >
        {/* <Route element={<Navigate to=':id' />} /> */}
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Profile</PageTitle>
              <Profile />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProfilePage
