import {useLocation, useNavigate} from 'react-router-dom'
import {ManageItem, useAuth} from '../../../web-app-core'

const API_URL = process.env.REACT_APP_API_URL

export default function Notifications() {
  const {auth} = useAuth()

  const formObject = {
    listEndpoint: `${API_URL}/user/${auth?.user?.id}/notification`,
    listTitle: 'Notifications',
    listFields: [
      {
        name: 'title',
        header: 'Title',
        type: 'link',
        path: '/notifications',
      },
    ],
    addEndpoint: '',
    addTitle: '',
    addFields: [],
    updateEndpoint: '',
    updateTitle: '',
    updateFields: [],
  }

  return <ManageItem formObject={formObject} />
}
